import { FC } from 'react';
import Image from 'next/image';

import useLocalised from 'customHooks/useLocalised/useLocalised';
import { partners_section_title } from 'data/translationKeys';
import { PartnerType } from 'types/types';
import FS from 'blocks/Partners/assets/FS.png';
import luxury from 'blocks/Partners/assets/luxury.png';
import smallLuxury from 'blocks/Partners/assets/small-luxury.png';
import PenClub from 'blocks/Partners/assets/PenClub.png';
import PearlPartner from 'blocks/Partners/assets/PearlPartner.png';
import marriott from 'blocks/Partners/assets/marriott.png';
import jumeirah from 'blocks/Partners/assets/jumeriah.png';
import rocco from 'blocks/Partners/assets/rocco.png';
import hyattprive from 'blocks/Partners/assets/hyattprive.png';
import elite from 'blocks/Partners/assets/elite.png';
import leadingHotels from 'blocks/Partners/assets/leadingHotels.png';
import virtuoso from 'blocks/Partners/assets/Virtuoso.gif';
import relaisChateaux from 'blocks/Partners/assets/RelaisChateux.png';
import IHGimg from 'blocks/Partners/assets/IHG.png';
import belmond from 'blocks/Partners/assets/BelmondBellini.png';
import trumpHotels from 'blocks/Partners/assets/trumpHotels.jpeg';
import barriere from 'blocks/Partners/assets/barriere.png';
import impresario from 'blocks/Partners/assets/Impresario.png';
import DesignHotels from 'blocks/Partners/assets/DesignHotels.png';
import MandarinOriental from 'blocks/Partners/assets/MandarinOriental.jpg';
import DorchesterCollection from 'blocks/Partners/assets/DorchesterCollection.jpg';

export const partnersWithImg: PartnerType[] = [
  {
    name: 'Four Seasons Preferred Partner',
    image: FS,
  },
  {
    name: 'Jumeirah Stay Different',
    image: jumeirah,
  },
  {
    name: 'The Leading Hotels Of The World',
    image: leadingHotels,
  },
  {
    name: 'Rose Wood Elite',
    image: elite,
  },
  {
    name: 'Rocco Forte Hotels',
    image: rocco,
  },
  {
    name: 'The Luxury Circle',
    image: luxury,
  },
  {
    name: 'The Peninsula Pen Club',
    image: PenClub,
  },
  {
    name: 'Mandarin Oriental FANS',
    image: MandarinOriental,
  },
  {
    name: 'Small Luxury Hotels Of The World',
    image: smallLuxury,
  },
  {
    name: 'Belmond Bellini Club',
    image: belmond,
  },
  {
    name: 'Pearl Partner Oetker Collection',
    image: PearlPartner,
  },
  {
    name: 'Hyatt Prive',
    image: hyattprive,
  },
  {
    name: 'Stars Marriott International',
    image: marriott,
  },
  {
    name: 'Virtuoso',
    image: virtuoso,
  },
  {
    name: 'Relais & Chateaux',
    image: relaisChateaux,
  },
  {
    name: 'IHG Hotels & Resorts',
    image: IHGimg,
  },
  {
    name: 'Trump Hotels',
    image: trumpHotels,
  },
  {
    name: 'Barrière Hotels',
    image: barriere,
  },
  {
    name: 'Impresario',
    image: impresario,
  },
  {
    name: 'Design Hotels',
    image: DesignHotels,
  },
  {
    name: 'Dorchester Collection Diamond Club',
    image: DorchesterCollection,
  },
];

interface IOurPartners {}

const Partners: FC<IOurPartners> = ({}) => {
  const partners_section_title_text = useLocalised(partners_section_title);

  return (
    <div className="partners-container">
      <div className="partners-container__title">
        {partners_section_title_text}
      </div>
      <div className="partners-container__icons-container">
        {partnersWithImg.map((partner, index) => (
          <div key={index} className="icon">
            <div className="icon__container">
              <div className="partner-icon">
                <Image
                  src={partner.image}
                  alt={partner.name}
                  objectFit="contain"
                  layout="fill"
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Partners;
