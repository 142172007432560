import { Color, Margins } from 'components/shared/types';
import { getMarginFromProps } from 'components/shared/utils';
import { getColorFromString } from 'components/text/utils';
import { FC, createElement } from 'react';

type DividerProps = {
  as?: keyof JSX.IntrinsicElements;
  direction?: 'h' | 'v';
  height?: React.CSSProperties['height'];
  width?: React.CSSProperties['width'];
  style?: React.CSSProperties;
  color?: Color;
} & Margins &
  React.HTMLAttributes<HTMLElement>;

const Divider: FC<DividerProps> = ({
  as = 'div',
  direction = 'h',
  height = 1,
  width,
  style,
  color = 'grey-medium',
  children,
  ...rest
}) => {
  const marginStyle = getMarginFromProps(rest);
  const colorStyle = getColorFromString(color);

  const backgroundColor = colorStyle || style?.backgroundColor;

  return createElement(as, {
    style: { ...marginStyle, ...style, width, height, backgroundColor },
    ...rest,
  });
};

export default Divider;
